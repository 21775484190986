<template>
  <v-row class="px-3 ml-md-0 pt-5">
    <h3>{{ $t("signUp") }}</h3>
    <span>{{ $t("enterEmailPassword") }}</span>
    <v-col cols="12" class="p-0">
      <v-text-field
        :label="$t('emailAddress')"
        required
        color="#59D79D"
        :hide-details="emailAddressError.length === 0"
        :error-count="emailAddressError.length"
        :error-messages="emailAddressError"
        v-model="email"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="p-0 relative">
      <v-text-field
        :label="$t('password')"
        required
        color="#59D79D"
        :hide-details="errors.length === 0"
        :error-count="errors.length"
        :error-messages="errors"
        :type="showPassword ? 'text' : 'password'"
        v-model="password"
      ></v-text-field>
      <v-icon v-show="showPassword" @click="onShowPassword" class="absolute">mdi-eye</v-icon>
      <v-icon v-show="!showPassword" @click="onShowPassword" class="absolute">mdi-eye-off</v-icon>
    </v-col>
    <v-col cols="12" class="p-0 relative">
      <v-text-field
        :label="$t('confirmPassword')"
        required
        color="#59D79D"
        :hide-details="confirmError.length === 0"
        :error-count="confirmError.length"
        :error-messages="confirmError"
        :type="showConfirmPassword ? 'text' : 'password'"
        v-model="confirmPassword"
      ></v-text-field>
      <v-icon v-show="showConfirmPassword" @click="onShowConfirmPassword" class="absolute">mdi-eye</v-icon>
      <v-icon v-show="!showConfirmPassword" @click="onShowConfirmPassword" class="absolute">mdi-eye-off</v-icon>
    </v-col>
    <v-col cols="4" class="py-0 pl-0">
      <v-autocomplete
        :items="phoneCodes"
        :label="$t('countryCode')"
        prefix="+"
        :hide-details="phoneCodeError.length === 0"
        :error-count="phoneCodeError.length"
        :error-messages="phoneCodeError"
        item-text="code"
        item-value="code"
        return-object
        v-model="phoneInternationalInfos"
        color="#59D79D"
      >
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-subtitle>
              <flag :iso="data.item.country" />
              <span class="pl-2"> {{ data.item.code }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="8" class="p-0">
      <v-text-field
        :label="$t('phoneNumber')"
        @keypress="onlyNumber"
        :counter="phoneInternationalInfos.phoneMask.length"
        required
        :maxlength="phoneInternationalInfos.phoneMask.length"
        color="#59D79D"
        :error-count="phoneError.length"
        :error-messages="phoneError"
        v-model="phoneNumber"
        v-mask="phoneInternationalInfos.phoneMask"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="p-0">
      <v-autocomplete
        :label="$t('typeUser')"
        :items="usersType"
        v-model="type_user"
        color="#59D79D"
        item-text="name"
        item-value="user_type"
        :disabled="subscriptionType.free"
        :hide-details="typeUserError.length === 0"
        :error-count="typeUserError.length"
        :error-messages="typeUserError"
        item-color="#59D79D"
        :search-input.sync="searchInput"
        @change="searchInput = ''"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" class="p-0">
    </v-col>
    <v-col cols="12" class="p-0">
      <v-checkbox
        v-model="checked"
        :hide-details="checkedError.length === 0"
        :error-count="checkedError.length"
        :error-messages="checkedError"
        color="#59D79D"
      >
        <template v-slot:label>
          <div class="font-size-14">
            <span class="black--text">{{ $t("iAccept") }}</span>
            <a
              target="_blank"
              href="/terminos-condiciones"
              @click.stop
              class="green-prosk"
            >
              {{ $t("termsAndConditions") }}
            </a>
          </div>
        </template>
      </v-checkbox>
    </v-col>
    <v-col cols="12" class="p-0">
      <div class="float-end">
        <save-button :save="onSubmit" :loading="loading" :message="$t('signUp')"/>
      </div>
    </v-col>
    <v-col offset="3" cols="9" class="mt-3 p-0">
      <v-btn
        small
        block
        color="#50b5ff"
        @click="registerGoogle"
        class="float-end white--text"
      >
        <v-icon left> mdi-google </v-icon>
        <span>{{ $t("registerGoogle") }}</span>
      </v-btn>
    </v-col>
    <v-col offset="3" cols="9" class="p-0 mt-3">
      <v-btn block small color="#4385f5" @click="registerFacebook" class="float-end white--text">
        <v-icon left>
          mdi-facebook
        </v-icon>
        <span>{{ $t("registerFacebook") }}</span>
      </v-btn>
    </v-col>
    <v-row class="pb-15 mt-3">
      <v-col
        cols="12"
        class="p-0"
        :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
      >
        <span class="dark-color d-inline-block line-height-2"
          >{{ $t("alreadyHaveAccount") }} ?
          <router-link :to="{ name: 'auth1.sign-in1' }" class="green-prosk">{{
            $t("logIn")
          }}</router-link>
        </span>
      </v-col>
      <v-col
        cols="12"
        class="p-0 pb-4"
        :class="$vuetify.breakpoint.mobile ? 'text-center' : ''"
      >
        <ul class="iq-social-media">
          <li>
            <a href="https://www.facebook.com/prosk.com.uy/" target="_blank">
              <v-icon color="#59D79D">mdi-facebook</v-icon>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/prosk/" target="_blank">
              <v-icon color="#59D79D">mdi-linkedin</v-icon>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/prosk.uy?igshid=YmMyMTA2M2Y=" target="_blank">
              <v-icon color="#59D79D">mdi-instagram</v-icon>
            </a>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { typeOnlyNumber, getPhoneCodes, removeMaskFromPhoneNumber } from '@/misc/utils';
import { mask } from 'vue-the-mask'

export default {
  name: 'SignUp1',
  directives: { mask },
  components: { SaveButton },
  mixins: [errorDialog],
  data () {
    const phoneCodes = getPhoneCodes();
    const defaultPhoneInternationalInfos = (this.$i18n.locale.includes('br')) ? phoneCodes[0] : phoneCodes[1];
    return {
      dateFormatted: null,
      menu: false,
      searchInput: '',
      searchPhoneCodesInput: '',
      phoneCodes: phoneCodes,
      phoneInternationalInfos: defaultPhoneInternationalInfos,
      usersType: [
        {
          id: 1,
          user_type: 10,
          name: 'Prosker'
        },
        {
          id: 2,
          user_type: 0,
          name: 'Cliente'
        }
      ],
      errors: [],
      confirmError: [],
      phoneCodeError: [],
      typeUserError: [],
      phoneError: [],
      checkedError: [],
      userNameError: [],
      emailAddressError: [],
      userName: '',
      firstName: null,
      type_user: null,
      lastName: null,
      subscriptionType: {},
      query: {},
      clientDataVerified: false,
      phoneNumber: null,
      email: null,
      password: '',
      confirmPassword: null,
      showPassword: false,
      showConfirmPassword: false,
      checked: false,
      loading: false,
      banner_img: null,
      profile_img: null
    };
  },
  watch: {
    'subscriptionType.free': function (value) {
      if (value) {
        this.type_user = 10;
      }
    }
  },
  created () {
    if (this.$route.params.query) {
      this.subscriptionType = JSON.parse(this.$route.params.query);
    }
  },
  methods: {
    onlyNumber ($event) {
      return typeOnlyNumber($event);
    },
    formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        if (this.$i18n.locale === 'en-US' || this.$i18n.locale === 'en') {
            return `${month}/${day}/${year}`
        } else {
            return `${day}/${month}/${year}`
        }
    },
    parseDate (date) {
        if (!date) return null
        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    onShowPassword () {
      this.showPassword = !this.showPassword;
    },
    onShowConfirmPassword () {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    resetErrors () {
      this.errors = [];
      this.phoneCodeError = [];
      this.confirmError = [];
      this.typeUserError = [];
      this.checkedError = [];
      this.phoneError = [];
      this.emailAddressError = [];
    },
    validatePhone (phone, phoneInternationalInfos) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumberForRegion(phoneUtil.parse(removeMaskFromPhoneNumber(phone), phoneInternationalInfos.country), phoneInternationalInfos.country)
    },
    validEmail (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    verifyClientData () {
      this.clientDataVerified = false;
      const passwordValidation = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_.;,\\])[A-Za-z\d@$!%*#?&\-_.;,\\]{8,}$/;
      if (!this.password.match(passwordValidation)) {
        this.errors.push(this.$t('invalidPassword'));
      }
      if (!this.phoneNumber) {
        this.phoneError.push(this.$t('fieldPhoneNumberRequired'));
      }
      if (!this.phoneInternationalInfos.code) {
        this.phoneCodeError.push(this.$t('fieldCountryCodeRequired'));
      }
      if (!this.validatePhone(this.phoneNumber, this.phoneInternationalInfos)) {
        this.phoneError.push(this.$t('enterValidPhone'));
      }
      if (this.password !== this.confirmPassword) {
        this.confirmError.push(this.$t('passwordsMustMatch'));
      }
      if (this.checked === false) {
        this.checkedError.push(this.$t('mustAcceptTerms'));
      }
      if (!this.email) {
        this.emailAddressError.push(this.$t('fieldEmailRequired'));
      }
      if (this.type_user === null) {
        this.typeUserError.push(this.$t('typeUserRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.emailAddressError.push(this.$t('enterValidEmail'));
      }
      if (
        this.password.match(passwordValidation) &&
        this.password === this.confirmPassword &&
        this.email &&
        this.checked === true &&
        this.validEmail(this.email) &&
        this.phoneNumber &&
        this.phoneNumber &&
        this.phoneNumber.length > 8 &&
        this.validatePhone(this.phoneNumber, this.phoneInternationalInfos) &&
        this.phoneInternationalInfos.code
      ) {
        this.clientDataVerified = true;
      }
    },
    registerFacebook () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/facebook';
    },
    registerGoogle () {
      window.location.href = process.env.VUE_APP_BASE_URL + '/login/google';
    },
    onSubmit () {
      // users type client = 0, prosker = 10, admin = 20
      this.resetErrors();
      this.verifyClientData();

      const username = this.email.split('@')[0]
        .replaceAll(/[^a-zA-Z0-9]/g, '');

      if (
        (this.type_user === 0 && this.clientDataVerified) ||
        (this.type_user === 10 &&
          this.clientDataVerified)
      ) {
        const url = `${process.env.VUE_APP_BASE_URL}/api/register`;
        const data = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          username,
          phone_code: this.phoneInternationalInfos.code,
          phone_number: removeMaskFromPhoneNumber(this.phoneNumber),
          type_user: this.type_user,
          password: this.password,
          password_confirmation: this.confirmPassword,
          banner_img: this.banner_img,
          profile_img: this.profile_img
        };
        this.loading = true;
        axios
          .post(url, data)
          .then(() => {
            this.loading = false;
            if (this.subscriptionType.free) {
              this.$swal.fire({
                title: this.$t('awesome'),
                text: this.$t('successfulFreePlanSubscription'),
                confirmButtonColor: '#19AD93'
              });
            }
            this.$router.push({
              name: 'auth1.confirm-mail1',
              params: { email: this.email }
            });
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
    }
  }
};
</script>
<style scoped>
  .relative {
    position: relative;
    width: 100%;
  }

  .absolute {
    position: absolute;
    top: 20px;
    right: 12px;
  }

  @media(max-width: 768px) {
    .absolute {
      top: 12px;
      right: 12px;
    }
  }
</style>
